.App {
    text-align: center;
    min-height: 100vh;
    background-color: whitesmoke;
}

a {
    color: white;
}

a:hover {
    text-decoration: underline;
    color: white;
}

#link {
    text-decoration: none;
    color: #3498db;
}

a:visited {
    text-decoration: none;
}

.App-header {
    background-image: url("./sky-34536-min.png");
    background-position: 0% 60%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 600px) {
        background-color: #3498db;
        background-image: none;
    }
}

h1 {
    font-size: 3rem;
    margin-bottom: 0;
    color: white;
}

h2 {
    font-size: 2rem;
}

.type-wrap span {
    padding: 0.05em;
    color: white;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.showcase {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
}

.bp3-card {
    margin: 1em;
}

.bp3-tag {
    margin: 1em;
    background-color: #3498db;
}

.footer {
    padding: 1em;
}
